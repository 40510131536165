import React from 'react';
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews';
import { Layout, PreviewBody } from 'components';

const PreviewPage = ({ location }) => (
  <Layout location={location}>
    <PreviewBody />
  </Layout>
);

export default withPrismicPreviewResolver(PreviewPage);
